import $ from "jquery"
import datatables from "datatables.net-dt"
datatables(window, $ );
const languageUrl = "https://cdn.datatables.net/plug-ins/1.11.3/i18n/de_de.json"

document.addEventListener("turbolinks:load", function() {
  $('#users-list-datatable').DataTable( {
    language: {
      url: languageUrl
    },
    dom: "ftpir",
    responsive: true,
    pagingType: "simple_numbers",
    columns: [
      null,
      { orderable: false },
      null,
      null,
      null,
      null,
      { orderable: false }
    ],
    fnDrawCallback: function() {
      $('[data-toggle="tooltip"]').tooltip();
    }
  });

  $('#storage-requests-list-datatable').DataTable( {
    language: {
      url: languageUrl
    },
    dom: "ftpir",
    responsive: true,
    pagingType: "simple_numbers",
    columns: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    fnDrawCallback: function() {
      $('[data-toggle="tooltip"]').tooltip();
    }
  });

  $('#my-storages-list-datatable').DataTable( {
    language: {
      url: languageUrl
    },
    dom: "ftpir",
    responsive: true,
    pagingType: "simple_numbers",
    columns: [
      null,
      null,
      null,
      null,
      { orderable: false },
      { orderable: false },
      { orderable: false },
      { orderable: false }
    ],
    fnDrawCallback: function() {
      $('[data-toggle="tooltip"]').tooltip();
    }
  });

    $('#storages-list-datatable').DataTable( {
      language: {
        url: languageUrl
      },
      dom: "ftpir",
      responsive: true,
      pagingType: "simple_numbers",
      columns: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        { orderable: false },
        { orderable: false },
      ],
      fnDrawCallback: function() {
        $('[data-toggle="tooltip"]').tooltip();
      }
    });

    $('#slugged-searches-datatable').DataTable( {
      language: {
        url: languageUrl
      },
      dom: "ftipr",
      responsive: true,
      pagingType: "simple_numbers",
      columns: [
        null,
        { orderable: false },
        { orderable: false },
        { orderable: false },
        { orderable: false },
        { orderable: false },
        { orderable: false },
      ],
      fnDrawCallback: function() {
        $('[data-toggle="tooltip"]').tooltip();
      }
    });
} );
